var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',_vm._g(_vm._b({attrs:{"min-width":_vm._.get(_vm.$attrs, 'min-width', '120'),"content-class":("cy-menu " + (_vm._.get(_vm.$attrs, 'content-class', ''))),"origin":_vm._.get(_vm.$attrs, 'origin', 'top right')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var value = ref.value;
return [_vm._t("activator",function(){return [_c('CyButton',_vm._g(_vm._b({attrs:{"disabled":_vm.$attrs.disabled,"data-cy":"menu-activator","variant":"tertiary","theme":"primary","icon":"more_horiz","icon-only":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},'CyButton',{
          sm: _vm.$attrs.sm,
        },false),on))]},null,{ on: on, value: value })]}}],null,true)},'v-menu',_vm.$attrs,false),_vm.$listeners),[_vm._t("default",function(){return [_c('v-list',[_vm._t("subheader"),_vm._l((_vm.visibleItems),function(item,index){
        var _obj;
return [(item.divider)?_c('v-divider',{key:index}):_c('v-list-item',{directives:[{name:"hover-siblings",rawName:"v-hover-siblings"}],key:index,class:[( _obj = {}, _obj[("cy-menu__item--" + (item.color))] = item.color, _obj['rainbow-hover'] =  item.isDevItem, _obj )],attrs:{"disabled":item.disabled},on:{"click":item.action}},[(item.icon)?_c('v-list-item-action',{staticClass:"menu__action-icon mr-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e(),(item.label)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1):_vm._e()],1)]})],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }